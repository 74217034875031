body {
  padding: 0;
}

.Root {
  background-color: #000;
  color: white;
}

.App {
  width: 80%;
  height: 100%;
  margin: auto;
  line-height: 1.5;
}

.App-logo {
  max-width: 500px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header, .App-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Navbar-container {
  width: 100%;
  height: 15vh;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-size: 10px; */
  /* font-style: italic; */
  /* font-weight: 500;
  letter-spacing: 1.2px;
  transition: .15s;
  text-transform: uppercase; */
}

.Nav-socials {
  display: flex;
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-page-plugins-content-divider {
  width: 100%;
  opacity: 0.4;
  margin: 3rem auto;
}

.copyright {
  font-size: 1rem;
  text-align: center;
}

.tac {
  text-align: center;
}